@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Amita&display=swap);
/* Raleway */

html {
  font-size: 62.5%;
  font-family: "Raleway", sans-serif;
  background-color: #1f1f1f;
  scroll-behavior: smooth;
}

body {
  background: url(/static/media/grain.3b56fe21.gif);
}

#loading {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  overflow: visible;
  background-color: #1f1f1f;
  background: url(/static/media/grain.3b56fe21.gif), url(/static/media/loading_gif_bg.e7152240.gif);
  background-repeat: no-repeat;
  background-position: center;
}

.loading-body {
  display: none;
}

h1,
h6 {
  font-family: "Raleway", sans-serif;
}

h2 {
  font-family: "Raleway", sans-serif;
  color: #e04343;
}

h2:hover {
  color: #878b7b;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

h3 {
  font-family: "Work Sans", sans-serif;
  color: #e7e9d1;
  font-style: normal;
  font-style: initial;
  font-size: 1.75rem;
}

h4 {
  font-family: "Raleway", sans-serif;
  color: #e04343;
  padding: 10px 0 0 0;
  font-size: 2rem;
}

h5 {
  color: #e7e9d1;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-style: initial;
  font-size: 2.2rem;
  padding: 0 5px 0 5px;
}

h5:hover {
  color: #878b7b;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

p {
  color: #e7e9d1;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-style: initial;
  font-size: 2.2rem;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: #878b7b;
  text-decoration: none;
}

#home {
  min-height: 100vh;
  width: auto;
  padding: 3% 2% 0 2%;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

*:focus {
  outline: none !important;
}

.logo {
  width: 75%;
  margin-top: 13vh;
  padding: 5vh 0;
}

.nav-box-cont {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
}

.navEase {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.portfolio-section {
  padding-top: 55px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio-piece {
  width: 48%;
  padding-bottom: 4%;
}

.portfolio-img {
  width: 80%;
  box-shadow: 0px -3px 20px 0px rgba(135, 139, 123, .5);
  border-radius: 5px 5px 0 0;
  opacity: 0.9;
  -webkit-filter: grayscale(100%) sepia(30%) blur(0.5px);
          filter: grayscale(100%) sepia(30%) blur(0.5px);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.portfolio-img:hover {
  opacity: 0.95;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-filter: none;
          filter: none;
}

.text-box {
  margin: 0 10%;
  padding: 0 20px 10px;
  background-color: rgba(231, 233, 209, 0.015);
  box-shadow: inset 0px 0px 10px rgba(231, 233, 209, 0.05),
    0 0 10px rgba(231, 233, 209, 0.05);
  border-radius: 0 0 5px 5px;
}

.trans {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-filter: grayscale(100%) blur(0px);
          filter: grayscale(100%) blur(0px);
}

.transBlur {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.transRight {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-filter: grayscale(100%) blur(3px);
          filter: grayscale(100%) blur(3px);
  /* transform: translate(5px, 0px); */
}

.transLeft {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-filter: grayscale(100%) blur(3px);
          filter: grayscale(100%) blur(3px);
  /* transform: translate(-5px, 0px); */
}

.scroll-hidden {
  display: none;
}

.scrollto {
  padding: 0 0px 0px 0px;
  display: block;
  color: #4e4b4b;
  width: 200px;
  margin: 15vh auto 0 auto;
  border-bottom: none;
  cursor: pointer;
}

.scrollto-text {
  color: #e04343;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.scrollto-text:hover {
  color: #878b7b;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.scrollto--arrow {
  padding: 10px;
}

.scrollto--arrow img {
  width: 25%;
  animation: arrowbounce 1s alternate infinite;
  -moz-animation: arrowbounce 1s alternate infinite;
  /* Firefox */
  -webkit-animation: arrowbounce 1s alternate infinite;
  /* Safari and Chrome */
  -o-animation: arrowbounce 1s alternate infinite;
  /* Opera */
}

/* Boucing Arrow Animation */
@keyframes arrowbounce {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-webkit-keyframes arrowbounce {
  /* Safari and Chrome */
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

/* tablet */

@media (max-width: 800px) {
  .logo {
    width: 100%;
    margin-bottom: 15%;
  }

  p {
    font-size: 2.3rem;
  }

  h3 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
    padding: 10px 0 0 0;
  }

  h5 {
    font-size: 2.3rem;
  }

  .scrollto {
    margin-top: 10vh;
    padding: 65vh 0px px 0px;
  }

  .portfolio-section {
    padding: 10px 0px 0px 0px;
  }

  .portfolio-piece {
    width: 95%;
    padding-bottom: 10%;
  }

  .footer-text {
    font-size: 1.25rem !important;
  }
}

/* mobile */

@media (max-width: 500px) {

  .logo {
    width: 100%;
    margin-bottom: 15%;
  }

  body {
    width: 100%;
  }

  p {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h5 {
    font-size: 1.75rem;
  }

  .row p {
    font-size: 2.2rem;
  }

  .scrollto {
    margin-top: 3vh;
    padding: 5vh 0px 0px 0px;
  }

  .scrollto-text {
    font-size: 2.3rem !important;
  }

  .scrollto-text:hover {
    color: #e04343;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .scrollto--arrow img {
    width: 35%;
    margin-top: 10px;
  }

  .portfolio-section {
    padding: 10px 0px 0px 0px;
  }

  .portfolio-piece {
    width: 85%;
    padding-bottom: 10%;
  }

  .portfolio-img {
    -webkit-filter: grayscale(100%) sepia(30%) blur(0);
            filter: grayscale(100%) sepia(30%) blur(0);
    box-shadow: 0px -3px 20px 0px rgba(135, 139, 123, .35);
    width: 100%;
  }

  .transRight {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    -webkit-filter: grayscale(100%) blur(1px);
            filter: grayscale(100%) blur(1px);
    /* transform: translate(5px, 0px); */
  }
  
  .transLeft {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    -webkit-filter: grayscale(100%) blur(1px);
            filter: grayscale(100%) blur(1px);
    /* transform: translate(-5px, 0px); */
  }

  .text-box {
    margin: 0;
    padding: 0 15px 10px;
  }

  .footer-text {
    font-size: 1.3rem !important;
  }
}

